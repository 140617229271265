<template>
  <div id="app" class="uk-flex uk-flex-column">
    <div id="header" class="uk-flex uk-flex-column uk-visible@l">
      <nav class="uk-navbar-container uk-flex uk-flex-column main-navbar" uk-navbar>
        <div class="uk-navbar-left uk-flex uk-flex-row uk-navbar-top">
          <ul class="uk-navbar-nav uk-flex uk-flex-row uk-navbar-top-left">
            <li><a href="/internet/house-panel/fiber">Приватним особам</a></li>
            <li><router-link class="router-link-internet-menu" to="/business">Бізнесу</router-link></li>
          </ul>
          <hr class="nav-hr">
          <ul class="uk-navbar-nav uk-flex uk-flex-row uk-navbar-top-right">
            <li>
              <a href="/news"><i class="far fa-newspaper nav-icon"></i>Новини</a>
            </li>
            <li>
            <button id="button-payment" class="uk-button uk-button-default" type="button"><a class="button-payment-toggle"><i class="fas fa-wallet nav-icon"></i>Оплата</a></button>
            <div uk-dropdown>
            <ul  class="uk-nav uk-dropdown-nav">

            <li id="payment-instruction" class="uk-nav-header news">Інструкції</li>
            <li class="uk-nav-divider"></li>
            <li><a href="/doc/privat24-manual.pdf">Приват24</a></li>
            <li><a href="/doc/terminal-manual.pdf">Термінал-ПриватБанк</a></li>
            <li><a href="/doc/Terminal-City24.pdf">Термінал City24</a></li>
            <li><a href="/doc/mono_payment-manual.pdf">Монобанк</a></li>
            <li><a href="/doc/terminal_oschad-manual.pdf">Термінал Ощадбанк</a></li>
            <li id="payment-links" class="uk-nav-header news">Сплатити</li>
            <li class="uk-nav-divider"></li>
            <li class="uk-light uk-background-muted"><a class="uk-text-danger" href="https://next.privat24.ua/payments/form/%7B%22companyID%22:%222330360%22,%22form%22:%7B%22query%22:%22%D0%93%D1%80%D0%B0%D0%BD%D0%B0%D1%82%22%7D%7D">Приват24</a></li>
            <li class="uk-light uk-background-secondary"><a class="uk-text-danger" href="https://city24.ua/ua/popolnit-internet/granat">City24</a></li>
            <li class="uk-light uk-background-muted"><a class="uk-text-danger" href="https://www.portmone.com.ua/r3/popovnyty-internet-granat-chernigiv">Portmone</a></li>
            </ul>
            </div>
            </li>
            <li>
              <a href="#modal-support" uk-toggle><i class="far fa-question-circle nav-icon"></i>Контакти</a>
            </li>
            <li>
              <a href="#modal-price" uk-toggle><i class="far fa-check-circle nav-icon"></i>Додаткові послуги</a>
            </li>
          </ul>
        </div>
        <div>
        </div>
        <div class="uk-navbar-right uk-flex uk-flex-row uk-navbar-bottom">
          <ul class="uk-navbar-nav uk-flex uk-flex-row nav-bottom-left uk-navbar-bottom-left">
            <li class="top-logo">
              <router-link to="/">
                <img class="logo" data-src="/img/logo.png" width="200" height="200" alt="" uk-img />
              </router-link>
            </li>
            <li><router-link to="/internet/house-panel/fiber">Інтернет</router-link></li>
            <li class="nav-dots">&bull;</li>
            <li><a href="/tv">Телебачення</a></li>
            <li class="nav-dots">&bull;</li>
          </ul>

          <ul class="uk-navbar-nav uk-flex uk-flex-row nav-bottom-right uk-navbar-bottom-right">
            <li>
              <a href="#modal-example" uk-toggle><i class="far fa-comments nav-icon"></i>Зв'язок</a>
            </li>
            <li>
              <a href="https://my.granatua.net/login"><i class="fas fa-user nav-icon"></i>Особистий кабінет</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
      
    <div class="uk-hidden@l mobile-menu">
     <router-link to="/">
                <img class="logotip" data-src="/img/logo.png" width="200" height="200" alt="" uk-img />
      </router-link>
      
    <span uk-icon="icon: receiver"></span>
      <a class="phone-item" href="tel:+380737838090">(073) 783-80-90</a>
      <a class="phone-item" href="tel:+380957838090">(095) 783-80-90</a>
      <a class="phone-item" href="tel:+380677838090">(067) 783-80-90</a>
      <span uk-icon="icon: mail"></span>
       <a class="mail-icon"  href="mailto:sales@granatua.net">sales@granatua.net</a>
       <a class="mail-icon"  href="mailto:support@granatua.net">support@granatua.net</a>
        
      <button class="uk-button .uk-width-1-1 uk-button-default mobile-nav" type="button">Меню
      <span uk-icon="icon: menu; ratio: 1.5"></span>
      </button>
      <div class="uk-width-large mobile-menu-div" uk-dropdown>
      <div class="uk-dropdown-grid uk-child-width-large uk-grid">
        <div>
            <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active">Послуги</li>
                <li><a href="/internet/house-panel/fiber">Інтернет</a></li>
                <li><a href="/business">Бізнесу</a></li>
                <li><a href="/tv/omega">Телебачення</a></li>
                <li class="uk-nav-divider"></li>
                <li class="uk-active">Документи</li>
                <li><a href="/doc/dogovir-oferta-internet.pdf">Договір Оферта (Інтернет)</a></li>
                <li><a href="/doc/dogovir-oferta-tv1.pdf">Договір Оферта (Телебачення)</a></li>
                <li><a href="/doc/blank.pdf">Бланк замовлення</a></li>
                <li><a href="/doc/operator-info.pdf">Інформація замовлення</a></li>
                <li><a href="/doc/zvit-fop.pdf">Звіт ФОП</a></li>
                <li><a href="/doc/dogovir-oferta-internet-fop.pdf">Договір Оферта ФОП</a></li>
                <li class="uk-nav-divider"></li>
            </ul>
        </div>
        <div>
            <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active">Обслуговування</li>
                <li><a href="/news">Новини</a></li>
                <li><a href="https://my.granatua.net/login">Особистий кабінет</a></li>
                <li><a uk-toggle href="#modal-price">Додаткові послуги</a></li>
                <li class="uk-nav-divider"></li>
                <li class="uk-active">Інше</li>
                <li><a uk-toggle href="#modal-example">Зв'язок</a></li>
                <li class="uk-nav-divider"></li>
            </ul>
         </div>
    </div>
</div>
    
    <button class="uk-button .uk-width-1-1 uk-button-default mobile-nav" type="button">Оплата
      <span uk-icon="icon: menu; ratio: 1.5"></span>
      </button>
      <div  class="uk-width-large " uk-dropdown>
      <div class="uk-dropdown-grid uk-child-width-large" uk-grid>
        <div>
            <ul class="uk-nav uk-dropdown-nav">
            <li class="uk-nav-header">Інструкції</li>
            <li class="uk-nav-divider"></li>
            <li><a href="/doc/privat24-manual.pdf">Приват24</a></li>
            <li><a  href="/doc/terminal-manual.pdf">Термінал-ПриватБанк</a></li>
            <li><a href="/doc/Terminal-City24.pdf">Термінал City24</a></li>
            <li><a href="/doc/mono_payment-manual.pdf">Монобанк</a></li>
            <li><a href="/doc/terminal_oschad-manual.pdf">Термінал Ощадбанк</a></li>
            <li class="uk-nav-divider"></li>
            <li class="uk-nav-header">Сплатити</li>
            <li class="uk-nav-divider"></li>
            <li class="uk-light uk-background-muted"><a class="uk-text-danger" href="https://next.privat24.ua/payments/form/%7B%22companyID%22:%222330360%22,%22form%22:%7B%22query%22:%22%D0%93%D1%80%D0%B0%D0%BD%D0%B0%D1%82%22%7D%7D">Приват24</a></li>
            <li class="uk-light uk-background-secondary"><a class="uk-text-danger" href="https://city24.ua/ua/popolnit-internet/granat">City24</a></li>
            <li class="uk-light uk-background-muted"><a class="uk-text-danger" href="https://www.portmone.com.ua/r3/popovnyty-internet-granat-chernigiv">Portmone</a></li>
            </ul>
        </div>
        </div>
        </div>

    </div>
    <router-view />
    <div class="footer uk-flex uk-flex-row">
      <div class="left-footer">
        <div class="summary2" >
          <div class="footer-item"><span class="footer-title">Про компанію</span></div>
          <a class="footer-link" href="/doc/dogovir-oferta-internet.pdf">Договір Оферта (Інтернет)</a>
          <a class="footer-link" href="/doc/dogovir-oferta-tv1.pdf">Договір Оферта (Телебачення)</a>
          <a class="footer-link" href="/doc/blank.pdf">Бланк замовлення</a>
          <a class="footer-link" href="/doc/operator-info.pdf">Інформація про оператора</a>
          <a class="footer-link" href="/doc/zvit-fop.pdf">Звіт ФОП</a>
          <a class="footer-link" href="/doc/dogovir-oferta-internet-fop.pdf">Договір Оферта ФОП</a>
          <a class="footer-link" href="/doc/protokol_parametriv.pdf">Протокол вимірювання параметрів електронних комунiкацiйних мереж ТОВ "Гранат Україна"</a>
          <a class="footer-link" href="/doc/protokol_vymirivannya.pdf">Протокол вимірювання параметрів електронних комунiкацiйних мереж ТОВ "Гранат Україна"</a>
          <a class="footer-link" href="/doc/atestat_vidpovidnosti.pdf">Атестат відповідності захищеного вузлу інтернет доступу ТОВ "Гранат Україна"</a>
        </div>
      </div>
      <div class="uk-flex uk-flex-row  right-footer">
        <div class="uk-flex uk-flex-column">
          <div class="footer-item"><span class="footer-title">Адреси офісів</span></div>
          <div uk-switcher="animation: uk-animation-fade; toggle: > *">
          <button class="uk-button uk-button-default" type="button">Прилуки</button>
          <button class="uk-button uk-button-default" type="button">Ніжин</button>
          <button class="uk-button uk-button-default" type="button">Ічня</button>
          <button class="uk-button uk-button-default" type="button">Варва</button>
          <button class="uk-button uk-button-default" type="button">Срібне</button>
          </div>

          <ul class="uk-switcher uk-margin">
          <li>
          <span class="footer-offices-info">Адреса офісу: м.Прилуки, вул. Переяславська, 72/1</span>
          <br>
          <span class="footer-offices-info">Час роботи: Пн-Пт 9:00-18:00</span>
          <br>
          <span uk-icon="icon: receiver"></span>
          <span><a class="phone-item" href="tel:+380673290804">(067) 329-08-04</a></span><br>
          <span uk-icon="icon: receiver"></span>
          <span><a class="phone-item" href="tel:+380502990804">(050) 299-08-04</a></span><br>
          </li>
          <li>
          <span class="footer-offices-info">Адреса офісу: м. Ніжин, вул. Синяківська 79</span>
          <br>
          <span class="footer-offices-info">Час роботи: Пн-Пт 9:00-18:00</span>
          <br>
          <span uk-icon="icon: receiver"></span>
          <span><a class="phone-item" href="tel:+380673189540">(067) 318-95-40</a></span>
          </li>
          <li>
          <span class="footer-offices-info">Адреса офісу: м. Ічня, вул. Шевченка 9, магазин «Матриця»</span>
          <br>
          <span class="footer-offices-info">Час роботи: Пн-Пт 9:00-18:00</span>
          <br>
          <span uk-icon="icon: receiver"></span>
          <span><a class="phone-item" href="tel:+380933483048">(093) 348-30-48</a></span><br>
          <span uk-icon="icon: receiver"></span>
          <span><a class="phone-item" href="tel:+380463324882">(0463) 32-48-82</a></span><br>
          <span uk-icon="icon: receiver"></span>
          <span><a class="phone-item" href="tel:+3803482970">(093) 348-29-70</a></span>
          </li>
          <li>
          <span class="footer-offices-info">Адреса офісу: смт.Варва, вул. Cадова, 1 а</span>
          <br>
          <span class="footer-offices-info">Час роботи: Пн-Пт 9:00-18:00</span>
          <br>
          <span uk-icon="icon: receiver"></span>
          <span><a class="phone-item" href="tel:+380933483048">(093) 348-30-48</a></span>
          </li>
          <li>
          <span class="footer-offices-info">Адреса офісу: смт. Срібне, вул. Незалежності 5</span>
          <br>
          <span class="footer-offices-info">Час роботи: Пн-Пт 9:00-18:00</span>
          <br>
          <span uk-icon="icon: receiver"></span>
          <span><a class="phone-item" href="tel:+380673659045"> (067) 365-90-45</a></span>
          </li>
          </ul>
      </div>
      </div>
    </div>
    
  <div id="modal-example" uk-modal>
    <div class="uk-modal-dialog uk-modal-body modal-question">
        <h2 class="uk-modal-title">З'явилось питання?</h2>
        <div>
        <div class="uk-margin">
        <div class="uk-inline calc-form-item">
            <span class="uk-form-icon calc-form-icon-item" uk-icon="icon: user"></span>
            <input class="uk-input calc-input" type="text" placeholder="Повне ім'я" v-model=name >
        </div>
    </div>
    <div class="uk-margin">
        <div class="uk-inline calc-form-item">
            <span class="uk-form-icon calc-form-icon-item" uk-icon="icon: mail"></span>
            <input class="uk-input calc-input" type="text" placeholder="Електронна пошта" v-model=email >
        </div>
    </div>
        </div>
        <div class="uk-margin">
            <textarea class="uk-textarea" rows="5" placeholder="Напишіть Ваше запитання" v-model=question></textarea>
        </div>
        <p class="uk-text-right">
            <button class="uk-button uk-button-default uk-modal-close question-close-button" type="button">Вийти</button>
            <button class="uk-button uk-button-primary question-submit-button" type="button" @click=sendQuestion >Відправити</button>
        </p>
    </div>
 </div>
  <div id="modal-support" uk-modal>
    <div class="uk-modal-dialog uk-modal-body modal-question">
        <h2 class="uk-modal-title-support">Контактні телефони</h2>
        <div class="uk-flex uk-flex row contact-item-support">
          <ul class="list-group">
          <li class="list-group-item"><span uk-icon="icon: receiver"></span><a class="phone-item" href="tel:+380737838090">(073) 783-80-90</a></li>
          <li class="list-group-item"><span uk-icon="icon: receiver"></span><a class="phone-item" href="tel:+380957838090">(095) 783-80-90</a></li>
          <li class="list-group-item"><span uk-icon="icon: receiver"></span><a class="phone-item" href="tel:+380677838090">(067) 783-80-90</a></li>
          </ul>
          </div>
          <h2 class="uk-modal-title-support">Електронна пошта</h2>
        <div class="uk-flex uk-flex row contact-item-support">
          <div>
          <ul class="list-group">
          <li class="list-group-item"><a class="mail-icon" href="mailto:sales@granatua.net">sales@granatua.net</a></li>
          <li class="list-group-item"><a class="mail-icon" href="mailto:support@granatua.net">support@granatua.net</a></li>
          </ul>
          </div>
          </div>
        <button class="uk-button uk-button-default uk-modal-close question-close-button" type="button">Вийти</button>
    </div>
    <div id="modal-price" uk-modal>
    <div class="uk-modal-dialog uk-modal-body modal-price">
        <h2 class="uk-modal-title-price">Додаткові послуги та обслуговування</h2>
         <table class="uk-table uk-table-divider">
    <thead>
        <tr>
            <th id="table-header-price1">Послуга</th>
            <th id="table-header-price2">Вартість</th>
        </tr>
    </thead>
    <tbody>
    <tr>
            <td>Маршрутизатор (Підтримує: 1Гб/с швидкість портів LAN, 2.4Ghz, 5Ghz WIFI діапазони)</td>
            <td>1600 грн</td>
        </tr>
    <tr>
            <td>Smart TV приставка</td>
            <td>1500 грн</td>
        </tr>
        <tr>
            <td>Виклик спеціаліста до абонента<span style="font-size: 18px">*</span></td>
            <td>200 грн/год + витрати на пальне</td>
        </tr>
        <tr id="table-price-header">
            <td>Додаткове налаштування маршрутизатора абонента</td>
            <td>від 50 грн</td>
        </tr>
        <tr>
            <td>Налаштування IPTV на обладнанні абонента</td>
            <td>від 50 грн за пристрій</td>
        </tr>
         <tr>
            <td>Монтаж кабелю «Звита пара» по приміщенню абонента</td>
            <td>15 грн/м</td>
        </tr>
          <tr>
            <td>Монтаж оптичного кабелю по приміщенню абонента</td>
            <td>10 грн/м</td>
        </tr>
         <tr>
            <td>Заміна оптичного коннектора на стороні абонента</td>
            <td>50 грн</td>
        </tr>
        <tr>
            <td>Заміна коннектора RJ-45 на стороні абонента</td>
            <td>50 грн</td>
        </tr>
        <tr>
            <td>Заміна оптичного терміналу на стороні абонента</td>
            <td>650 грн</td>
        </tr>
           <tr>
            <td>Заміна блока живлення оптичного терміналу на стороні абонента</td>
            <td>150 грн</td>
        </tr>
         <tr>
            <td>Зміна розташування абонентського радіотерміналу</td>
            <td>500 грн</td>
        </tr>
        <tr>
            <td><span style="font-size: 18px">*</span>За наявності проблеми, що з’явилася на стороні абонента або за його вини.</td>
        </tr>
        <tr>
            <td><span style="font-size: 18px">*</span>Мінімальна тарифікація — 1 година.</td>
        </tr>
        <tr>
            <td><span style="font-size: 18px">*</span>За бажання абонента</td>
        </tr>
    </tbody>
</table> 
        <button class="uk-button uk-button-default uk-modal-close price-close-button" type="button">Вийти</button>
    </div>
    </div>
  </div>

<notifications group="foo" />
  </div>
</template>

<script>
import UIkit from "uikit"
import Icons from "uikit/dist/js/uikit-icons"
import {postTask} from "@/common"

UIkit.use(Icons);
export default {

  name: "App",
  
  data() 
  {
    return {
      name: "",
      email: "",
      question: "",
    }
  },

  methods: {
    async sendQuestion()
    {
      const form = {
         type: 'question',
         title: 'Запитання',
         description: `${this.question}`,
         task_attr: {
           email: this.email,
           fio: this.name,
         }
      }
      await postTask(form)
    },
  },

};
</script>

<style lang="less">
@import "../node_modules/uikit/src/less/uikit.less";
@import "./assets/less/theme.less";
@import "./assets/less/main.css";

#app {
  height: 100%;
}

#header {
  height: 16vh;
  background-color: white !important;
}

#payment-instruction {
  color: rgb(199,33,39);
}

#payment-links {
  color: rgb(199,33,39);
}

.uk-icon {
  color: rgb(199,33,39);
}

.mail-icon {
  color: rgb(199,33,39);
}


.disabled {
    pointer-events:none; //This makes it not clickable
    opacity:0.6;         //This grays it out to look disabled
}

.uk-active {
  color:rgb(199,33,39) !important;
}

.footer-offices-info {
  color: gray;
}

.list-group {
  font-size: 20px;
  list-style-type: none;
}

#button-payment {
  border: none;
  margin-top: 2px;
}

.button-payment-toggle {
  color: black;
}

.button-payment-toggle:hover {
  color: red;
}

.main-navbar {
  background-color: white !important;
  margin-left:10%;
  margin-right: 10%;
  height: inherit;
}

@media screen and (max-width: 540px) {
      .summary2{
        display: none;
      }
      .right-footer{
  justify-content: flex-end;
  text-align: right;
}
.internet-menu {
  margin-top: 20%;
}
.tv-page{
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 20%;
    
}
}

@media screen and (min-width: 540px) {
      .summary2
      {
      display: flex;
      flex-direction: column;
      }
      .right-footer{
     width: 50%;
    justify-content: flex-end;
    text-align: right;
}
}

.adresses {
  width: 30%;
}
.uk-drop {
    display: none;
    position: absolute;
    z-index: 1020;
    box-sizing: border-box;
    width: 360px;
}

.phone-item{
  color: rgb(102,102,102);
  text-decoration: none !important;
  outline: none !important;
}

.uk-modal-title-support {
  border-bottom: 1px;
  border-bottom-style: solid;
  border-color: rgb(199,33,39);
}

.uk-modal-title-price {
  border-bottom: 1px;
  border-bottom-style: solid;
  border-color: rgb(199,33,39);
}

#table-header-price1 {
    color: #c72127;
    font-size: 20px;
}

#table-header-price2 {
    color: #c72127;
    font-size: 20px;
}

.mobile-menu-div {
    z-index: 500;
}

.phone-item-support{
  color: rgb(102,102,102);
  text-decoration: none !important;
  outline: none !important;
}

.phone-item:hover{
  color: rgb(199,33,39);
}

.contact-item{
  margin-bottom: 1em;
  justify-content: flex-end;
}

.footer-link{
  line-height:2em;
  color: grey;
  text-decoration: none !important;
  outline: none !important;
}

.contact-number{
  margin-right: 1em;
}

.footer-link:hover{
  color: rgb(199,33,39);
}

.footer-item{
  margin-bottom: 10px;
}

.uk-navbar-top{
  margin: 0;
  height: 40%;
  justify-content: space-between;
}

.mobile-nav{
  border: 0px;
}

.footer-title{
  font-size: 18px;
  border-bottom: 1px solid rgb(165, 165, 165);
  margin-bottom: 10px;
}

.uk-navbar-bottom{
  margin: 0;
  height: 60%;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.nav-hr{
  width: 14%;
}

.uk-navbar-top-left > li > a {
  font-size: 15px !important;
}

.uk-navbar-top-right > li > a {
  font-size: 15px !important;
}

.uk-navbar-bottom-left > li > a {
  font-weight: 500;
}

.uk-navbar-top-left, .uk-navbar-bottom-right{
  justify-content: flex-start;
}

.uk-navbar-top-right, .uk-navbar-bottom-right{
  justify-content: flex-end;
}

.question-submit-button{
  background-color: rgb(199,33,39) !important;
  margin-left: 10px;
}

.nav-dots{
  color: rgb(199,33,39);
  display: flex;
  align-items: center;
  cursor: default;
}

.calc-block:hover, .pay-block:hover {
  box-shadow: 0px 2px 0 darken(grey, 5%), 2px 10px 6px;
  transition: all 250ms linear;
  background-color: darken(white, 1%);
}

.logo{
  max-width: 100%;
}

.logotip{
 
}

.mobile-menu{
  margin-bottom: 15%;
  margin-left: 10%;
  margin-right: 10%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}



@media screen and (max-width: 540px) { 
  .mobile-menu{
  margin-bottom: 15%;
  margin-left: 10%;
  margin-right: 10%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}
}

@media screen and (min-width: 540px) { 
  .mobile-menu{
  margin-bottom: 15%;
  margin-left: 10%;
  margin-right: 10%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}
}

@media screen and (min-width: 540px) and (orientation: landscape) { 
  .mobile-menu{
  margin-bottom: 15%;
  margin-left: 10%;
  margin-right: 10%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}
}

@media screen and (min-width: 1200px) {
  .footer{
  height: 35vh;
  margin-top: 4em;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
}
}
@media screen and (max-width: 1200px) {
  .footer{
  height: 35vh;
  margin-top: 1em;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
}
}

textarea{
  resize: none;
}

textarea:focus{
  border-color: rgb(199,33,39) !important
}

.left-footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}



.summary{
  display: flex;
  flex-direction: column;
  
}
.summary1{
  display: flex;
  flex-direction: column;
}


.uk-navbar-nav > li > a {
  min-height: 40px;
  text-transform: none;
  color: black;
  font-size: 17px;
}

.nav-icon {
  margin-right: 5px;
  color: black;
}
.nav-icon:hover {
  color: red;
}

</style>
